import React from 'react'
  /* @jsx mdx */
import { mdx } from '@mdx-js/react';
/* @jsx mdx */

import DefaultLayout from "/opt/build/repo/node_modules/gatsby-theme-docz/src/base/Layout.js";
import { Playground } from 'docz';
import { UsePersistedStateExample } from './use-persisted-state.example.tsx';
export const _frontmatter = {};

const makeShortcode = name => function MDXDefaultShortcode(props) {
  console.warn("Component " + name + " was not imported, exported, or provided by MDXProvider as global scope");
  return <div {...props} />;
};

const layoutProps = {
  _frontmatter
};
const MDXLayout = DefaultLayout;
export default function MDXContent({
  components,
  ...props
}) {
  return <MDXLayout {...layoutProps} {...props} components={components} mdxType="MDXLayout">



    <h1 {...{
      "id": "usepersistedstate"
    }}>{`usePersistedState`}</h1>
    <p>{`Use persisted state as a drop in replacement for Reacts built-in hook
`}<inlineCode parentName="p">{`useState`}</inlineCode>{`.`}</p>
    <p>{`By default it will use the key `}<inlineCode parentName="p">{`local-storage-hook`}</inlineCode>{`. But you probably want to
set another key if you plan to use this hook on several places across your
application.`}</p>
    <p>{`Any changes to state will be emitted both across your application and across
instances of you application running in other windows/tabs.`}</p>
    <h2 {...{
      "id": "usage"
    }}>{`Usage`}</h2>
    <pre><code parentName="pre" {...{
        "className": "language-jsx"
      }}>{`import React from 'react';
import { usePersistedState } from '@fransvilhelm/hooks';

const Counter = () => {
  const [count, setCount] = usePersistedState(0, 'counter');
  return (
    <div>
      <button onClick={() => setCount(count - 1)}>-</button>
      <output>{count}</output>
      <button onClick={() => setCount(count + 1)}>+</button>
    </div>
  );
};
`}</code></pre>
    <h2 {...{
      "id": "example"
    }}>{`Example`}</h2>
    <Playground __position={0} __code={'<UsePersistedStateExample />'} __scope={{
      props,
      DefaultLayout,
      Playground,
      UsePersistedStateExample,
      DefaultLayout,
      _frontmatter
    }} mdxType="Playground">
  <UsePersistedStateExample mdxType="UsePersistedStateExample" />
    </Playground>
    <h2 {...{
      "id": "parameters"
    }}>{`Parameters`}</h2>
    <p><inlineCode parentName="p">{`usePersistedState`}</inlineCode>{` accepts two arguments, the default initial state and a key
to store the state on (preferably unique across your application).`}</p>
    <table>
      <thead parentName="table">
        <tr parentName="thead">
          <th parentName="tr" {...{
            "align": "left"
          }}>{`Param`}</th>
          <th parentName="tr" {...{
            "align": "left"
          }}>{`Type`}</th>
          <th parentName="tr" {...{
            "align": "left"
          }}>{`Required`}</th>
          <th parentName="tr" {...{
            "align": "left"
          }}>{`Default`}</th>
          <th parentName="tr" {...{
            "align": "left"
          }}>{`Description`}</th>
        </tr>
      </thead>
      <tbody parentName="table">
        <tr parentName="tbody">
          <td parentName="tr" {...{
            "align": "left"
          }}>{`initialState`}</td>
          <td parentName="tr" {...{
            "align": "left"
          }}>{`S `}{`|`}{` () => S`}</td>
          <td parentName="tr" {...{
            "align": "left"
          }}>{`true`}</td>
          <td parentName="tr" {...{
            "align": "left"
          }}>{`-`}</td>
          <td parentName="tr" {...{
            "align": "left"
          }}>{`Default initial state, if non is found on `}<inlineCode parentName="td">{`localStorage`}</inlineCode></td>
        </tr>
        <tr parentName="tbody">
          <td parentName="tr" {...{
            "align": "left"
          }}>{`key`}</td>
          <td parentName="tr" {...{
            "align": "left"
          }}>{`string`}</td>
          <td parentName="tr" {...{
            "align": "left"
          }}>{`false`}</td>
          <td parentName="tr" {...{
            "align": "left"
          }}>{`local-storage-hook`}</td>
          <td parentName="tr" {...{
            "align": "left"
          }}>{`Key to persist value to`}</td>
        </tr>
      </tbody>
    </table>
    <h2 {...{
      "id": "returns"
    }}>{`Returns`}</h2>
    <p><inlineCode parentName="p">{`usePersistedState`}</inlineCode>{` returns an array tuple, similar to `}<inlineCode parentName="p">{`useState`}</inlineCode>{`. The first
value is the current state, the second is a state updater function.`}</p>
    <table>
      <thead parentName="table">
        <tr parentName="thead">
          <th parentName="tr" {...{
            "align": "left"
          }}>{`Index`}</th>
          <th parentName="tr" {...{
            "align": "left"
          }}>{`Item`}</th>
          <th parentName="tr" {...{
            "align": "left"
          }}>{`Type`}</th>
          <th parentName="tr" {...{
            "align": "left"
          }}>{`Description`}</th>
        </tr>
      </thead>
      <tbody parentName="table">
        <tr parentName="tbody">
          <td parentName="tr" {...{
            "align": "left"
          }}>{`0`}</td>
          <td parentName="tr" {...{
            "align": "left"
          }}>{`state`}</td>
          <td parentName="tr" {...{
            "align": "left"
          }}>{`S`}</td>
          <td parentName="tr" {...{
            "align": "left"
          }}>{`Current state`}</td>
        </tr>
        <tr parentName="tbody">
          <td parentName="tr" {...{
            "align": "left"
          }}>{`1`}</td>
          <td parentName="tr" {...{
            "align": "left"
          }}>{`setState`}</td>
          <td parentName="tr" {...{
            "align": "left"
          }}>{`function`}</td>
          <td parentName="tr" {...{
            "align": "left"
          }}>{`A state updater function, accepts either a number or a factory function retrieving the current state and returns a new state`}</td>
        </tr>
      </tbody>
    </table>

    </MDXLayout>;
}
;
MDXContent.isMDXComponent = true;
      